import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart , CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';
import { Box, Typography } from '@mui/material';


echarts.use(
    [VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent, BarChart ]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        // width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));



export default function MonthIntChart(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);

    const fakeData=[{
        x: "1月",
        y: 120000
    },{
        x: "2月",
        y: 220000
    },{
        x: "3月",
        y: 120000
    },{
        x: "4月",
        y: 0
    },{
        x: "5月",
        y: 0
    },{
        x: "6月",
        y: 330000
    },{
        x: "7月",
        y: 3400
    },{
        x: "8月",
        y: 120000
    },{
        x: "9月",
        y: 120000
    },{
        x: "10月",
        y: 120000
    },{
        x: "11月",
        y: 120000
    },{
        x: "12月",
        y: 120000
    },

    ]

    function genoption1() {
        let k = {
            animation:false,
            grid: [{
                top: 10,
                left: 0,
                right: 0,
                // height: 100,
                bottom: 0,
                containLabel:true
            }],
            xAxis: [{
                type: 'category',
                boundaryGap: true,
                data: fakeData.map(row => row.x),
            }],
            yAxis: [{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.2
                    }
                }                
            }],
            series: [
                {
                    name: '價格',
                    type: 'bar',
                    data: fakeData.map(row => row.y),
                    smooth: true,
                    showSymbol: false,
                }
            ]
        };
        return k;
    }

    useEffect(() => {
        setOption(genoption1)
        // if(props.prices != null) setOption(genoption1)
    }, []);

    const clickDetail = () => {
        if(props.setOpen != null) props.setOpen(true)
    }

    return (
        <Box sx={{mt:0.5,ml: 1, mr: 1}}  onClick={e => clickDetail()}>
            <Typography variant="h6">每月股息分佈</Typography>
            <Typography variant="caption">全年：32萬，平均:2.3萬/月</Typography>
            {option != null ?
                <ReactEChartsCore
                    theme={theme.palette.mode}
                    echarts={echarts}
                    option={option}
                    notMerge={true}
                    lazyUpdate={false}
                    style={{ height: 150 }}
                // onEvents={onEvents}
                />
                : null}
        </Box>
    );
}

