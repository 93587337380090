import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Lightbulb, PropaneTwoTone } from '@mui/icons-material';
import FavoriteBorderOutlined from '@mui/icons-material/FavoriteBorderOutlined'
import Favorite from '@mui/icons-material/Favorite'
import { getId } from '../util/ID'

import Avatar from '@mui/material/Avatar';
import { deepOrange, green } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import RecBase from './RecBase1'
import ButtonGroup from '@mui/material/ButtonGroup';
import MetaItem from './MetaItem';
import LoveSetting from './LoveSetting'

export default function RecItem(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);
    const [tid, setTid] = React.useState(null);

    const openMenu = Boolean(anchorEl);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    const getColor = () => {
        if (props.row != null && props.row.price != null) {
            if (props.row.price.i > 0) return "red"
            else if (props.row.price.i < 0) return "green"
            else return null
        } else return null
    }

    const getInv = () => {
        if (props.row != null && props.row.price != null) {
            if (props.row.price.i > 0) return "▲" + props.row.price.i + '元'
            else if (props.row.price.i < 0) return "▼" + props.row.price.i + '元'
            else return props.row.price.i + '元'
        } else if (props.row.price != null)
            return props.row.price.i + '元'
        else return '▲0元'
    }


    const love = (lid) => {
        setAnchorEl(null);
        setTid(lid)
        setOpenSetting(true)
    }



    return (
        <>
            {openSetting ? <LoveSetting name={props.row.company.short_name} sid={props.row.company.stock_no} tid={tid} open={openSetting} close={setOpenSetting}></LoveSetting> : null}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >

                <MenuItem value={"0"} onClick={e => love("0")}>定期投資計畫1</MenuItem>
                <MenuItem value={"1"} onClick={e => love("1")}>定期投資計畫2</MenuItem>
                <MenuItem value={"2"} onClick={e => love("2")}>定期投資計畫3</MenuItem>
                <MenuItem value={"3"} onClick={e => love("3")}>定期投資計畫4</MenuItem>
                <MenuItem value={"4"} onClick={e => love("4")}>定期投資計畫5</MenuItem>
            </Menu>
            <Stack spacing={0} sx={{ m: 1, mb: 0.5, p: 0, border: 0.5, borderColor: theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main, boxShadow: 0, borderRadius: '0px' }}>

                <Box display={'flex'}>
                    <Stack direction='row' spacing={0.5} sx={{ flex: 1, p: 0, pl: 1, pt: 0.5, alignSelf: 'center' }} >
                        {props.row.islv ? <Favorite color="error" onClick={handleMenuClick} /> :
                            <FavoriteBorderOutlined onClick={handleMenuClick} />}
                        <Typography sx={{ whiteSpace: "nowrap", fontWeight: "bold", fontSize: 30, alignSelf: 'center' }} onClick={e => setOpen(true)}>{props.row.company.short_name.substr(0, 5)}</Typography>
                        <Typography variant="caption" sx={{ fontWeight: 'normal' }} onClick={e => setOpen(true)}>{props.row.company.stock_no}</Typography>
                    </Stack>
                    <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }} onClick={e => setOpen(true)}>
                        <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{props.row.price != null ? props.row.price.p : "-"}</Typography>
                        <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{getInv()}</Typography>
                    </Stack>
                </Box>
                {/* , bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main */}
                <Divider sx={{}}

                // style={{
                //     // border: "none",
                //     // height: 0.5,
                //     margin: 0,
                //     borderWidth: 6
                // }} 
                />
                <MetaItem row={props.row} openDetail2={props.openDetail2} openDetail1={props.openDetail1} openDetail={props.openDetail} menu={props.menu} setOpen={setOpen} />

                {/* <RecBase openDetail2={props.openDetail2} openDetail1={props.openDetail1} openDetail={props.openDetail} row={props.row} menu={props.menu} setOpen={setOpen} /> */}

                {/* <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    margin: 0,
                }} variant="middle" /> */}

            </Stack>
        </>
    );
}
