import React, { useEffect, useRef } from 'react';
import RecItem from './RecItem'
import { Height } from '../util/ScreenHeight'
import RecSelector from './RecSelector'
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { getId } from '../util/ID'

import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material';
import RateDetail from './RateDetail'
import DetailChart1 from './DetailChart1'
import DetailKChart from './DetailKChart'
import { putInpQueryParam } from '../util/ID'



export default function RecContainer(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(1)
    const [count, setCount] = React.useState(0)

    const [result, setResult] = React.useState(null);
    const [menu, setMenu] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [tp, setTp] = React.useState(0)
    const [rules, setRules] = React.useState(null)
    const [openSetting, setOpenSetting] = React.useState(false);
    const [stock, setStock] = React.useState(null);

    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [openSetting2, setOpenSetting2] = React.useState(false);
    const [id, setId] = React.useState(null);
    const [sid, setSid] = React.useState(null);

    const [regTp, setRegTp] = React.useState(-1);
    const [runYear, setRunYear] = React.useState(-1);
    const [stp, setSTP] = React.useState(-1);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const updateSelector = (selectorTp, selectorYear, selectorStp) => {
        setRegTp(selectorTp)
        setRunYear(selectorYear)
        setSTP(selectorStp)
    }

    const fetchData = () => {
        setLoading(true)

        let rules = localStorage.getItem('reg_search_rules') != null ? JSON.parse(localStorage.getItem('reg_search_rules')) : []
        let sort = localStorage.getItem('reg_search_sort') != null ? localStorage.getItem('reg_search_sort') : '0'
        let money = localStorage.getItem('reg_search_money') != null ? localStorage.getItem('reg_search_money') : '10000'
        let asset_inc = localStorage.getItem('reg_search_asset_inc') != null ? localStorage.getItem('reg_search_asset_inc') : '10000'
        let buy_num = localStorage.getItem('reg_search_buy_num') != null ? localStorage.getItem('reg_search_buy_num') : '1000'
        let stp = localStorage.getItem('reg_search_stp') != null ? localStorage.getItem('reg_search_stp') : '0'


        let param = {
            page: page,
            sn: getId(),
            rules: rules,
            inp: {},
            unit: 0,
            money : money,
            ttp : regTp,
            run_year: runYear,
            sort : sort,
            asset_inc: asset_inc,
            buy_num: buy_num,
            stp: stp
        }

        if (localStorage.getItem("reg_lic") != null) {
            param['lic'] = localStorage.getItem("reg_lic")
        }

        param = putInpQueryParam(param)

        fetch("/regular/v1/app/get", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setLoading(false)

                    if (result != null && result.status === 0) {
                        if (result.result1 != null && result.result1.length > 0) {
                            setTotal(result.total_page)
                            setResult(result.result1)
                            setCount(result.count)
                        } else {
                            setCount(0)
                            setTotal(0)
                            setResult([])
                        }
                    }
                }
            );
    }

    useEffect(() => {
        if(regTp != -1 && runYear != -1) fetchData()
    }, [page, regTp, runYear, stp]);



    // useEffect(() => {
    //     if (props.sig != null) {
    //         setPage(1)
    //         setMenu(props.sig)
    //     }
    // }, [props.sig]);


    const updateLove = (sid) => {
        if (sid != null && result != null) {
            let nresult = result.map(r => {
                if (r.stock === sid) {
                    r['islv'] = !r.islv
                    return r
                } else return r
            })
            setResult(nresult)
        }
    }

    const openDetail = (stock) => {
        setStock(stock)
        setOpenSetting(true)
    }

    const openDetail1 = (sid) => {
        setSid(sid)
        setOpenSetting1(true)
    }

    const openDetail2 = (id) => {
        setSid(id)
        setOpenSetting2(true)
    }

    return (
        <Box style={{ flexDirection: 'column', height: '100%', overflowY: 'hidden' }} sx={{}}>
            {openSetting && <RateDetail openDetail2={openDetail2} openDetail1={openDetail1} stock={stock} open={openSetting} close={setOpenSetting}></RateDetail>}
            {openSetting1 && <DetailChart1 regTp={regTp} runYear={runYear} id={id} sid={sid} open={openSetting1} close={setOpenSetting1} />}
            {openSetting2 && <DetailKChart sid={sid} open={openSetting2} close={setOpenSetting2} />}


            <Box style={{ flexDirection: 'column', height: '100%', overflowY: 'hidden' }} sx={{}}>
                <RecSelector fetchData={fetchData} setMenu={setMenu} refresh={updateSelector} enableSetting={true} />

                {/* <Grid container spacing={0} sx={{ p: 0.5, width: '100%', bgcolor: 'background.paper', position: 'absolute', top: Height.header + Height.subheader, bottom: props.ismobile || fullScreen ? 100 : 55, overflowY: 'scroll' }}>
                    {[...Array(10).keys()].map(index => {
                        return <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                            <RecItem openDetail2={openDetail2} openDetail1={openDetail1} openDetail={openDetail} updateLove={updateLove} tp={tp} key={row.stock} row={row} menu={menu} />
                        </Grid>
                    })}
                </Grid> */}
                {loading ? <Box sx={{ position: 'absolute', top: Height.header + Height.subheader - 2, width: '100%' }}><LinearProgress color="info" /></Box> : <Grid container spacing={0} sx={{ p: 0.5, width: '100%', bgcolor: 'background.paper', position: 'absolute', top: Height.header + Height.subheader, bottom: props.ismobile || fullScreen ? 100 : 55, overflowY: 'scroll' }}>
                    {result != null && result.map(row => {
                        return <Grid key={row.no} item xs={12} sm={6} md={4} lg={3}>
                            <RecItem openDetail2={openDetail2} openDetail1={openDetail1} openDetail={openDetail} updateLove={updateLove} tp={tp} key={row.no} row={row} menu={menu} />
                        </Grid>
                    })}
                    {result != null && result.length === 0 &&
                        <Typography variant="body1" sx={{ width: '100%', textAlign: 'center', alignSelf: 'center', m: 1 }}>很抱歉，無法找到符合條件的股票。</Typography>
                    }

                </Grid>}

                {result != null && result.length > 0 && < Box style={{ flexShrink: 0 }} sx={{ position: 'absolute', bottom: props.ismobile || fullScreen ? 60 : 0, width: '100%', mt: 0.5, mb: 0.5, display: 'flex', justifyContent: "center" }}>
                    <Pagination size="small" siblingCount={1} boundaryCount={1} count={total} variant="outlined" shape="rounded" onChange={handlePageChange} />
                    {/* (共{count}筆) */}
                </Box>}
            </Box >


        </Box >
    );
}
