import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Lightbulb, PropaneTwoTone } from '@mui/icons-material';
import FavoriteBorderOutlined from '@mui/icons-material/FavoriteBorderOutlined'
import Favorite from '@mui/icons-material/Favorite'
import { getId } from '../util/ID'

import Avatar from '@mui/material/Avatar';
import { deepOrange, green, grey, orange } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ButtonGroup from '@mui/material/ButtonGroup';
import MetaItem from './MetaItem';
import ActionMenu from './ActionMenu';
import TranDialog from './TranDialog'
import LinearWithValueLabel from './LinearWithValueLabel'
import { priceWord } from '../util/Money';
import RecHeaderBar from './RecHeaderBar';
import TargetDialog from './TargetDialog';

export default function RecHeader(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);


    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);
    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [openSetting2, setOpenSetting2] = React.useState(false);

    const openMenu = Boolean(anchorEl);


    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const openDetail = (dtp) => {
    }

    const getColor = () => {
        if (props.row != null && props.row.price != null) {
            if (props.row.price.i > 0) return "red"
            else if (props.row.price.i < 0) return "green"
            else return null
        } else return null
    }

    const getInv = () => {
        if (props.row != null && props.row.price != null) {
            if (props.row.price.i > 0) return "▲" + props.row.price.i + '元'
            else if (props.row.price.i < 0) return "▼" + props.row.price.i + '元'
            else return props.row.price.i + '元'
        } else if (props.row.price != null)
            return props.row.price.i + '元'
        else return '▲0元'
    }




    const clickLove = () => {
        let param = {
            sn: getId(),
            sid: props.row.stock_no,
        }

        if (localStorage.getItem("reg_lic") != null) {
            param['lic'] = localStorage.getItem("reg_lic")
        }

        fetch("/regular/v1/love/clicklove", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.status === 0) {
                        // if(props.refresh != null) props.refresh()
                        if (props.updateLove != null) props.updateLove(props.row.stock_no)
                    }
                }
            );
    }

    const handleMenuChange = (newValue) => {
        setAnchorEl(null);
        setOpenSetting(true)
    };

    const options = [
        '新增交易',
        '新增定期規則',
        '刪除',
    ];

    const menuOk = (option, id) => {
        if (option === 1) {
            setOpenSetting1(true)
        } else if (option === 2) {
        } else if (option === 4) {
        }
    }


    return (
        <>
            {openSetting1 && <TranDialog refresh={props.refresh} tid={props.tid} row={props.row} open={openSetting1} close={setOpenSetting1} />}
            {openSetting2 && <TargetDialog refresh={props.refresh} tid={props.tid} row={props.row} open={openSetting2} close={setOpenSetting2} />}


            <Stack onClick={e => setOpenSetting2(true)} spacing={0} sx={{bgcolor:theme.palette.mode === "light" ? grey[50]: grey[900] , m: 1, mb: 0.5, p: 1.5, border: 0.5, borderColor: theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main, boxShadow: 0, borderRadius: '0px' }}>
                <Typography variant='h6'>股利目標：{priceWord(props.mm)} / {priceWord(props.money)}</Typography>
                <RecHeaderBar value={((props.mm * 100) / props.money)} ></RecHeaderBar>
            </Stack>
        </>
    );
}
