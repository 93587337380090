import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import { Height } from '../util/ScreenHeight'
// import ErrorM from '../util/ErrorM'
// import AskDialog from '../util/AskDialog';
// import Photo from '@mui/icons-material/Photo'
// import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import MoreVert from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import RecSetting from './RecSetting';

import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import AlertD from '../util/AlertD';
import { Stack, Typography } from '@mui/material';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -10,
        top: 5,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
            // width: 250,
        },
    },
};


const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 0
        },
    }

    //  '& .MuiDataGrid-colCell, .MuiDataGrid-cell': {
    //     borderRight: `1px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
    //         }`,
    // },
}));


export default function RecSelector(props) {
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [openSetting, setOpenSetting] = React.useState(false);

    const theme = useTheme();
    const classes = useStyles();

    const [regTp, setRegTp] = React.useState(0);
    const [runYear, setRunYear] = React.useState(1);
    const [stp, setSTP] = React.useState(localStorage.getItem('reg_search_stp') != null ? localStorage.getItem('reg_search_stp') : '0');

    const handleChange = (event) => {
        if (hasLic())
        setRegTp(event.target.value);
        else setRegTp(0)
    };

    const handleYearChange = (event) => {
        setRunYear(event.target.value)
    };

    const handleSTPChange = (event) => {
        localStorage.setItem("reg_search_stp", event.target.value)
        setSTP(event.target.value)
    };

    useEffect(() => {
        if (props.refresh != null) props.refresh(regTp, runYear, stp)
    }, [regTp, runYear, stp]);

    const hasLic = () => {
        return localStorage.getItem('noad') != null && localStorage.getItem('noad') === "1"
    }


    return (
        <Box sx={{ width: '100%', textAlign: 'left', display: 'inline-flex', p: 0, bgcolor: theme.palette.mode === "light" ? "secondary.light" : null }} >
            {openSetting ? <RecSetting refresh={props.fetchData} open={openSetting} close={setOpenSetting}></RecSetting> : null}
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}

            <Stack direction={"row"} spacing={0.2} sx={{ width: "100%", height: Height.subheader - 3, maxHeight: Height.subheader }}>
                <FormControl variant="filled" fullWidth hiddenLabel >
                    <Select
                        labelId="tpLabel"
                        id="tp"
                        value={regTp}
                        label="tpLabel"
                        onChange={handleChange}
                    >
                        <MenuItem value={0}>定期定額</MenuItem>
                        {hasLic() ? <MenuItem value={1}>定期定股</MenuItem>:<MenuItem value={1}>定期定股(VIP專屬)</MenuItem>}
                        {hasLic() ? <MenuItem value={2}>定期定值</MenuItem>:<MenuItem value={2}>定期定值(VIP專屬)</MenuItem>}
                    </Select>
                </FormControl>
                <FormControl variant="filled" fullWidth hiddenLabel >
                    <Select
                        labelId="yearLabel"
                        id="year"
                        value={runYear}
                        label="yearLabel"
                        onChange={handleYearChange}
                    >
                        <MenuItem value={1}>1年</MenuItem>
                        <MenuItem value={2}>2年</MenuItem>
                        <MenuItem value={3}>3年</MenuItem>
                        <MenuItem value={4}>4年</MenuItem>
                        <MenuItem value={5}>5年</MenuItem>
                        <MenuItem value={6}>6年</MenuItem>
                        <MenuItem value={7}>7年</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="filled" fullWidth hiddenLabel >
                    <Select
                        labelId="stpLabel"
                        id="stp"
                        value={stp}
                        label="類型"
                        onChange={handleSTPChange}
                    >
                        <MenuItem value={'0'}>{'全部'}</MenuItem>
                        <MenuItem value={'1'}>{'股票'}</MenuItem>
                        <MenuItem value={'2'}>{'ETF'}</MenuItem>
                    </Select>
                </FormControl>

               

            </Stack>
            {/* {props.enableSetting && <Button variant='contained' sx={{ height: Height.subheader - 3, maxHeight: Height.subheader, whiteSpace: 'nowrap', borderRadius: '0px', mr: 0.2}} size="small" color="secondary" disableElevation onClick={e => clear()}>清除</Button>} */}
            {props.enableSetting && <Button variant='contained' sx={{ height: Height.subheader - 3, maxHeight: Height.subheader, whiteSpace: 'nowrap', borderRadius: '0px' }} size="small" color="error" disableElevation onClick={e => setOpenSetting(true)}>更多</Button>}
        </Box>
    );
}
