import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { PieChart, LineChart, BarChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';
import { Box, Button, Typography } from '@mui/material';
import { getId } from '../util/ID'
import { priceWord, addIncColor } from '../util/Money'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

echarts.use(
    [PieChart, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent, BarChart]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        // width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));



export default function TargetChart(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);


    const fetchData = () => {
        let param = {
            sn: getId(),
            tid: props.tid,
        }


        if (localStorage.getItem("reg_lic") != null) {
            param['lic'] = localStorage.getItem("reg_lic")
        }


        fetch("/regular/v1/target/getOne/detail", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.status === 0) {
                        if (result.target1 != null) {
                            setResult(result.target1)
                        }
                    }
                }
            );
    }

    function genoption1() {
        let k = {
            animation: false,
            title: [{
                text: '個股貢獻',
                // subtext: 'Fake Data',
                left: 'left'
            }, {
                top: 280,
                text: '每月收入',
                // subtext: 'Fake Data',
                left: 'left'
            }],

            tooltip: {
                trigger: 'axis'
            },
            grid: [{
                top: 50,
                left: 5,
                right: 5,
                height: 200,
                containLabel: true
            }, {
                top: 330,
                left: 5,
                right: 5,
                height: 200,
                // bottom: 0,
                containLabel: true
            }],
            xAxis: [{
                type: 'category',
                boundaryGap: true,
                data: result.c1.map(row => row.n),
                gridIndex: 0,
            }, {
                type: 'category',
                boundaryGap: true,
                data: result.m1.map(row => row.x),
                gridIndex: 1,
                axisLabel: {
                    formatter: function (value, index) {
                        return value + "月"
                    }
                }
            }],
            yAxis: [{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.2
                    }
                },
                gridIndex: 0,
            }, {
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.2
                    }
                },
                gridIndex: 1,


            }],
            series: [
                {
                    name: '股利分配',
                    type: 'bar',
                    data: result.c1.map(row => row.v1),
                    smooth: true,
                    showSymbol: false,
                    yAxisIndex: 0,
                    xAxisIndex: 0,
                    label: {
                        show: true,
                        formatter: function (params) {
                            return params.value + "%"
                        },
                        position: "top"
                    }
                },
                {
                    name: '月股利',
                    type: 'bar',
                    data: result.m1.map(row => row.y),
                    smooth: true,
                    showSymbol: false,
                    yAxisIndex: 1,
                    xAxisIndex: 1,
                    label: {
                        show: true,
                        formatter: function (params) {
                            if (params.value > 0)
                                return priceWord(params.value)
                            else return ""
                        },
                        position: "top"
                    }
                },
                // {
                //     name: '價格',
                //     type: 'line',
                //     data: result.map(row => row.price),
                //     smooth: true,
                //     showSymbol: false,
                //     yAxisIndex: 1,
                // }
            ]
        };
        return k;
    }

    useEffect(() => {
        fetchData()
    }, []);

    useEffect(() => {
        if (result != null) setOption(genoption1)
        // if(props.prices != null) setOption(genoption1)
    }, [result]);

    const clickDetail = () => {
        if (props.setOpen != null) props.setOpen(true)
    }

    return (
        <Box sx={{ m: 1.5 }} >

            {/* <Typography variant="h6">預估總股息
            </Typography>
            <Typography variant="caption">目標:50萬，實際：23.3萬，完成日：2033年</Typography>
             */}
            {option != null ?
                <ReactEChartsCore
                    theme={theme.palette.mode}
                    echarts={echarts}
                    option={option}
                    notMerge={true}
                    lazyUpdate={false}
                    style={{ height: 550 }}
                // onEvents={onEvents}
                />
                : null}

            {result != null && <TableContainer component={Paper} >
                <Table size="medium" >
                    <TableHead sx={{ bgcolor: 'black' }}>
                        <TableRow>
                            <TableCell sx={{ color: "white" }} align="center">月份</TableCell>
                            <TableCell sx={{ color: "white" }} align="center">股票</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {result.m2.map((row) => (
                            <TableRow
                                key={row.n}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="center">
                                    <Button disableElevation sx={{width: 200}} variant='contained' color='inherit' >{row.n}月</Button>
                                    
                                </TableCell>
                                <TableCell align="center">{row.n2}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>}

        </Box>
    );
}

