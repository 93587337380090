export const ColorPalette = [
    '#2ec7c9',
    '#b6a2de',
    '#5ab1ef',
    '#ffb980',
    '#d87a80',
    '#8d98b3',
    '#e5cf0d',
    '#97b552',
    '#95706d',
    '#dc69aa',
    '#07a2a4',
    '#9a7fd1',
    '#588dd5',
    '#f5994e',
    '#c05050',
    '#59678c',
    '#c9ab00',
    '#7eb00a',
    '#6f5553',
    '#c14089'
];

export const EchartTheme = {
    "seriesCnt": "7",
    "backgroundColor": "rgba(0, 0, 0, 0)",
    "titleColor": "#464646",
    "subtitleColor": "#6E7079",
    "textColorShow": false,
    "textColor": "#333",
    "markTextColor": "#eee",
    "color": [
        "#5470c6",
        "#91cc75",
        "#fac858",
        "#ee6666",
        "#73c0de",
        "#3ba272",
        "#fc8452",
        "#9a60b4",
        "#ea7ccc"
    ],
    "borderColor": "#ccc",
    "borderWidth": 0,
    "visualMapColor": [
        "#bf444c",
        "#d88273",
        "#f6efa6"
    ],
    "legendTextColor": "#333",
    "kColor": "#eb5454",
    "kColor0": "#47b262",
    "kBorderColor": "#eb5454",
    "kBorderColor0": "#47b262",
    "kBorderWidth": 1,
    "lineWidth": 2,
    "symbolSize": 4,
    "symbol": "emptyCircle",
    "symbolBorderWidth": 1,
    "lineSmooth": false,
    "graphLineWidth": 1,
    "graphLineColor": "#aaa",
    "mapLabelColor": "#000",
    "mapLabelColorE": "rgb(100,0,0)",
    "mapBorderColor": "#444",
    "mapBorderColorE": "#444",
    "mapBorderWidth": 0.5,
    "mapBorderWidthE": 1,
    "mapAreaColor": "#eee",
    "mapAreaColorE": "rgba(255,215,0,0.8)",
    "axes": [
        {
            "type": "all",
            "name": "通用坐标轴",
            "axisLineShow": true,
            "axisLineColor": "#6E7079",
            "axisTickShow": true,
            "axisTickColor": "#6E7079",
            "axisLabelShow": true,
            "axisLabelColor": "#6E7079",
            "splitLineShow": true,
            "splitLineColor": [
                "#E0E6F1"
            ],
            "splitAreaShow": false,
            "splitAreaColor": [
                "rgba(250,250,250,0.2)",
                "rgba(210,219,238,0.2)"
            ]
        },
        {
            "type": "category",
            "name": "类目坐标轴",
            "axisLineShow": true,
            "axisLineColor": "#6E7079",
            "axisTickShow": true,
            "axisTickColor": "#6E7079",
            "axisLabelShow": true,
            "axisLabelColor": "#6E7079",
            "splitLineShow": false,
            "splitLineColor": [
                "#E0E6F1"
            ],
            "splitAreaShow": false,
            "splitAreaColor": [
                "rgba(250,250,250,0.2)",
                "rgba(210,219,238,0.2)"
            ]
        },
        {
            "type": "value",
            "name": "数值坐标轴",
            "axisLineShow": false,
            "axisLineColor": "#6E7079",
            "axisTickShow": false,
            "axisTickColor": "#6E7079",
            "axisLabelShow": true,
            "axisLabelColor": "#6E7079",
            "splitLineShow": true,
            "splitLineColor": [
                "#E0E6F1"
            ],
            "splitAreaShow": false,
            "splitAreaColor": [
                "rgba(250,250,250,0.2)",
                "rgba(210,219,238,0.2)"
            ]
        },
        {
            "type": "log",
            "name": "对数坐标轴",
            "axisLineShow": false,
            "axisLineColor": "#6E7079",
            "axisTickShow": false,
            "axisTickColor": "#6E7079",
            "axisLabelShow": true,
            "axisLabelColor": "#6E7079",
            "splitLineShow": true,
            "splitLineColor": [
                "#E0E6F1"
            ],
            "splitAreaShow": false,
            "splitAreaColor": [
                "rgba(250,250,250,0.2)",
                "rgba(210,219,238,0.2)"
            ]
        },
        {
            "type": "time",
            "name": "时间坐标轴",
            "axisLineShow": true,
            "axisLineColor": "#6E7079",
            "axisTickShow": true,
            "axisTickColor": "#6E7079",
            "axisLabelShow": true,
            "axisLabelColor": "#6E7079",
            "splitLineShow": false,
            "splitLineColor": [
                "#E0E6F1"
            ],
            "splitAreaShow": false,
            "splitAreaColor": [
                "rgba(250,250,250,0.2)",
                "rgba(210,219,238,0.2)"
            ]
        }
    ],
    "axisSeperateSetting": true,
    "toolboxColor": "#999",
    "toolboxEmphasisColor": "#666",
    "tooltipAxisColor": "#ccc",
    "tooltipAxisWidth": 1,
    "timelineLineColor": "#DAE1F5",
    "timelineLineWidth": 2,
    "timelineItemColor": "#A4B1D7",
    "timelineItemColorE": "#FFF",
    "timelineCheckColor": "#316bf3",
    "timelineCheckBorderColor": "fff",
    "timelineItemBorderWidth": 1,
    "timelineControlColor": "#A4B1D7",
    "timelineControlBorderColor": "#A4B1D7",
    "timelineControlBorderWidth": 1,
    "timelineLabelColor": "#A4B1D7"
}

export const EchartDarkTheme = {
    "backgroundColor": "#000000",
    
}

export const EchartDarkTheme1 = {
    "seriesCnt": "7",
    "backgroundColor": "rgba(41,52,65,1)",
    "titleColor": "#ffffff",
    "subtitleColor": "#dddddd",
    "textColorShow": false,
    "textColor": "#333",
    "markTextColor": "#293441",
    "color": [
        "#fc97af",
        "#87f7cf",
        "#f7f494",
        "#72ccff",
        "#f7c5a0",
        "#d4a4eb",
        "#d2f5a6",
        "#76f2f2"
    ],
    "borderColor": "#ccc",
    "borderWidth": 0,
    "visualMapColor": [
        "#fc97af",
        "#87f7cf"
    ],
    "legendTextColor": "#999999",
    "kColor": "#fc97af",
    "kColor0": "transparent",
    "kBorderColor": "#fc97af",
    "kBorderColor0": "#87f7cf",
    "kBorderWidth": "2",
    "lineWidth": "3",
    "symbolSize": "0",
    "symbol": "circle",
    "symbolBorderWidth": "4",
    "lineSmooth": true,
    "graphLineWidth": "1",
    "graphLineColor": "#ffffff",
    "mapLabelColor": "#893448",
    "mapLabelColorE": "rgb(137,52,72)",
    "mapBorderColor": "#999999",
    "mapBorderColorE": "#eb8146",
    "mapBorderWidth": 0.5,
    "mapBorderWidthE": 1,
    "mapAreaColor": "#f3f3f3",
    "mapAreaColorE": "rgba(255,178,72,1)",
    "axes": [
        {
            "type": "all",
            "name": "通用坐标轴",
            "axisLineShow": true,
            "axisLineColor": "#666666",
            "axisTickShow": false,
            "axisTickColor": "#333",
            "axisLabelShow": true,
            "axisLabelColor": "#aaaaaa",
            "splitLineShow": false,
            "splitLineColor": [
                "#e6e6e6"
            ],
            "splitAreaShow": false,
            "splitAreaColor": [
                "rgba(250,250,250,0.05)",
                "rgba(200,200,200,0.02)"
            ]
        },
        {
            "type": "category",
            "name": "类目坐标轴",
            "axisLineShow": true,
            "axisLineColor": "#333",
            "axisTickShow": true,
            "axisTickColor": "#333",
            "axisLabelShow": true,
            "axisLabelColor": "#333",
            "splitLineShow": false,
            "splitLineColor": [
                "#ccc"
            ],
            "splitAreaShow": false,
            "splitAreaColor": [
                "rgba(250,250,250,0.3)",
                "rgba(200,200,200,0.3)"
            ]
        },
        {
            "type": "value",
            "name": "数值坐标轴",
            "axisLineShow": true,
            "axisLineColor": "#333",
            "axisTickShow": true,
            "axisTickColor": "#333",
            "axisLabelShow": true,
            "axisLabelColor": "#333",
            "splitLineShow": true,
            "splitLineColor": [
                "#ccc"
            ],
            "splitAreaShow": false,
            "splitAreaColor": [
                "rgba(250,250,250,0.3)",
                "rgba(200,200,200,0.3)"
            ]
        },
        {
            "type": "log",
            "name": "对数坐标轴",
            "axisLineShow": true,
            "axisLineColor": "#333",
            "axisTickShow": true,
            "axisTickColor": "#333",
            "axisLabelShow": true,
            "axisLabelColor": "#333",
            "splitLineShow": true,
            "splitLineColor": [
                "#ccc"
            ],
            "splitAreaShow": false,
            "splitAreaColor": [
                "rgba(250,250,250,0.3)",
                "rgba(200,200,200,0.3)"
            ]
        },
        {
            "type": "time",
            "name": "时间坐标轴",
            "axisLineShow": true,
            "axisLineColor": "#333",
            "axisTickShow": true,
            "axisTickColor": "#333",
            "axisLabelShow": true,
            "axisLabelColor": "#333",
            "splitLineShow": true,
            "splitLineColor": [
                "#ccc"
            ],
            "splitAreaShow": false,
            "splitAreaColor": [
                "rgba(250,250,250,0.3)",
                "rgba(200,200,200,0.3)"
            ]
        }
    ],
    "axisSeperateSetting": false,
    "toolboxColor": "#999999",
    "toolboxEmphasisColor": "#666666",
    "tooltipAxisColor": "#cccccc",
    "tooltipAxisWidth": 1,
    "timelineLineColor": "#87f7cf",
    "timelineLineWidth": 1,
    "timelineItemColor": "#87f7cf",
    "timelineItemColorE": "#f7f494",
    "timelineCheckColor": "#fc97af",
    "timelineCheckBorderColor": "#fc97af",
    "timelineItemBorderWidth": 1,
    "timelineControlColor": "#87f7cf",
    "timelineControlBorderColor": "#87f7cf",
    "timelineControlBorderWidth": 0.5,
    "timelineLabelColor": "#87f7cf",
    "datazoomBackgroundColor": "rgba(255,255,255,0)",
    "datazoomDataColor": "rgba(114,204,255,1)",
    "datazoomFillColor": "rgba(114,204,255,0.2)",
    "datazoomHandleColor": "#72ccff",
    "datazoomHandleWidth": "100",
    "datazoomLabelColor": "#333333"
}