import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';
import { Box, Typography } from '@mui/material';


echarts.use(
    [VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent, BarChart]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        // width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));



export default function AssetChart(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);

    const fakeData = [{
        x: "2021",
        y: 120000
    }, {
        x: "2022",
        y: 220000
    }, {
        x: "2023",
        y: 320000
    }, {
        x: "2024",
        y: 420000
    }, {
        x: "2025",
        y: 520000
    }, {
        x: "2026",
        y: 620000
    }, {
        x: "2027",
        y: 720000
    }, {
        x: "2028",
        y: 820000
    }, {
        x: "2029",
        y: 920000
    }, {
        x: "2030",
        y: 1020000
    }, {
        x: "2031",
        y: 1120000
    }, {
        x: "2032",
        y: 1220000
    },

    ]

    function genoption1() {
        let k = {
            animation: false,
            grid: [{
                top: 10,
                left: 0,
                right: 0,
                // height: 100,
                bottom: 0,
                containLabel: true
            }],
            xAxis: [{
                type: 'category',
                boundaryGap: true,
                data: fakeData.map(row => row.x),
            }],
            yAxis: [{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.2
                    }
                }
            }],
            series: [
                {
                    name: '價格',
                    type: 'bar',
                    data: fakeData.map(row => row.y),
                    smooth: true,
                    showSymbol: false,
                    markLine: {
                        symbol: ['none', 'none'],
                        label: {
                            show: true,
                            formatter: "現在"
                        },
                        data: [
                            {
                                name: '現在',
                                yAxis: "820000"
                            }
                        ]
                    },

                    // markLine: {
                    //     data: [
                    //         {
                    //             name: 'Horizontal line with Y value at 100',
                    //             yAxis: 120000
                    //         },
                    //     ]
                    // }
                }
            ]
        };
        return k;
    }

    useEffect(() => {
        setOption(genoption1)
        // if(props.prices != null) setOption(genoption1)
    }, []);

    const clickDetail = () => {
        if (props.setOpen != null) props.setOpen(true)
    }

    return (
        <Box sx={{ mt: 0.5, ml: 1, mr: 1 }} onClick={e => clickDetail()}>
            
            <Typography variant="h6">預估總股息
            </Typography>
            <Typography variant="caption">目標:50萬，實際：23.3萬，完成日：2033年</Typography>
            
            {option != null ?
                <ReactEChartsCore
                    theme={theme.palette.mode}
                    echarts={echarts}
                    option={option}
                    notMerge={true}
                    lazyUpdate={false}
                    style={{ height: 150 }}
                // onEvents={onEvents}
                />
                : null}
        </Box>
    );
}

