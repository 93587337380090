import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ITEM_HEIGHT = 48;

export default function ActionMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (option) => {
    let action = 0

    if(option=== "盤點庫存") {
      action = 1  
    } else if(option=== "新增定期規則") {
      action = 2
    } else if(option=== "登錄市價") {
      action = 3
    } else if(option=== "刪除負債") {
      action = 4
    } else if(option=== "修改") {
      action = 5
    } else if(option=== "刪除") {
      action = 6
    } else if(option=== "更新目標") {
      action = 7
    } 

    if(props.menuOk != null) props.menuOk(action, props.id, props.data)
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{p: 0}}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {props.options != null && props.options.map((option) => (
          <MenuItem key={option} onClick={e => handleMenu(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
