import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import AllStrageRules from '../strage/AllStrageRules'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchCompany from './SearchCompany'
import { getId } from '../util/ID'
import AlertD from '../util/AlertD';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as dayjs from 'dayjs'
import { priceWord, addIncColor } from '../util/Money'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { grey } from '@mui/material/colors';
import TranChart from './TranChart';

dayjs.locale("zh-tw");

export default function TransDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [price, setPrice] = React.useState(null);
    const [piece, setPiece] = React.useState(null);
    const [tp, setTP] = React.useState(0);
    const [ymd, setYMD] = React.useState(null);
    const [result, setResult] = React.useState(null);

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const fetchData = () => {
        let param = {
            sn: getId(),
            tid: props.tid,
            lid: props.row.id
        }

        if (localStorage.getItem("reg_lic") != null) {
            param['lic'] = localStorage.getItem("reg_lic")
        }


        fetch("/regular/v1/target/tran/all", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0 && result.trans != null) {
                        setResult(result.trans.all)
                    }
                }
            );
    }

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    const handleOKClose = () => {
        if (props.close != null) props.close(false)
        if (props.refresh != null) props.refresh()
    };

    useEffect(() => {
        if (props.open) {
            setOpen(true)
            fetchData()
            // setYMD(dayjs(new Date().toISOString()).format('YYYY/MM/DD'))
        }
        else setOpen(false)
    }, [props.open]);

    const handleChange = (event) => {
        setTP(event.target.value);
    };

    const del = (row) => {
        let param = {
            sn: getId(),
            tid: props.tid,
            lid: props.row.id,
            iid: row.id
        }

        if (localStorage.getItem("reg_lic") != null) {
            param['lic'] = localStorage.getItem("reg_lic")
        }


        fetch("/regular/v1/target/tran/del", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    fetchData()
                }
            );
    }

    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"庫存記錄"}
                </DialogTitle>
                <DialogContent sx={{ pl: 1, pr: 1 }}>
                {result != null && result.length === 0 && <Typography variant='body2'>目前沒有任何紀錄</Typography>}
                    {result != null && result.length > 0 && <TranChart result={result} />}

                    {result != null && result.length > 0 && <TableContainer component={Paper} >
                        <Table size="medium" >
                            <TableHead sx={{ bgcolor: 'black' }}>
                                <TableRow>
                                    <TableCell sx={{ color: "white" }}>日期</TableCell>
                                    <TableCell sx={{ color: "white" }} align="center">張數</TableCell>
                                    <TableCell sx={{ color: "white" }} align="center">價格</TableCell>
                                    <TableCell sx={{ color: "white" }} align="center">動作</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {result.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.ymd}
                                        </TableCell>
                                        <TableCell align="center">{row.piece / 1000}張</TableCell>
                                        <TableCell align="center">{priceWord(row.price)}</TableCell>
                                        <TableCell align="center">
                                            <Button color="inherit" fullWidth disableElevation variant="contained" onClick={e => del(row)}>刪除</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>}



                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={handleClose}>關閉</Button> */}
                    {/* isabled={localStorage.getItem("noad") != "1"} */}
                    <Button disableElevation color="inherit" fullWidth variant='contained' onClick={handleClose}>關閉</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
