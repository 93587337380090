import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import AllStrageRules from '../strage/AllStrageRules'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { getId } from '../util/ID'
import AlertD from '../util/AlertD';

export default function LoveSetting(props) {
    const [open, setOpen] = React.useState(false);
    const [money, setMoney] = React.useState(null);
    const [piece, setPiece] = React.useState(null);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const fetchData = () => {
        let param = {
            sn: getId(),
            tid: props.tid,
            stock: props.sid,
            piece: piece * 1000,
            money: money * 10000,
        }

        if (localStorage.getItem("reg_lic") != null) {
            param['lic'] = localStorage.getItem("reg_lic")
        }


        fetch("/regular/v1/target/r1", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setOpen(false);
                    if (props.close != null) props.close(false)

                    if (props.refresh != null) {
                        props.refresh()
                    }
                }
            );
    }

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    const handleOKClose = () => {
        if(piece == null) {
            setAlertMsg("請輸入股數目標")
            setShowAlert(true)
        }  else if(money == null) {
            setAlertMsg("請輸入股利目標")
            setShowAlert(true)
        } else {
            fetchData()
        }
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);



    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"加入計畫"}
                </DialogTitle>
                <DialogContent sx={{ pl: 1, pr: 1 }}>
                    <Stack spacing={3} direction="column" sx={{ p: 2 }}>
                        <Typography variant='h6'>{props.name}({props.sid})</Typography>                        
                        <TextField
                            fullWidth
                            id="outlined-controlled"
                            label="庫存目標"
                            value={piece}
                            onChange={(event) => {
                                setPiece(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">張</InputAdornment>,
                            }}
                        />

                        <TextField
                            fullWidth
                            id="outlined-controlled"
                            label="年股利目標"
                            value={money}
                            onChange={(event) => {
                                setMoney(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">萬元 / 年</InputAdornment>,
                            }}
                        />
                    </Stack>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    {/* isabled={localStorage.getItem("noad") != "1"} */}
                    <Button d onClick={handleOKClose} autoFocus>
                        新增
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
