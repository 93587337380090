import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Container, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import { deepOrange, green, grey } from '@mui/material/colors';
import Badge from '@mui/material/Badge';
import KChart from './KChart';
import { priceWord, addIncColor } from '../util/Money'
import { styled } from '@mui/material/styles';
import MuiGrid from '@mui/material/Grid';
import AllIntChart from './AllIntChart';

const Grid = styled(MuiGrid)(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& [role="separator"]': {
        margin: theme.spacing(0, 2),
    },
}));

export default function MetaItem(props) {
    const theme = useTheme();
    const [openSetting, setOpenSetting] = React.useState(false);


    const getColor = (value) => {
        if (value > 0) return "error"
        else if (value < 0) return "success"
        else return "inherit"
    }

    const clickDetail = () => {
        if(props.openDetail1 != null) props.openDetail1(props.row.company.stock_no)
    }

    const getYield = () => {
        return ((props.row.mii.cash * 100) / props.row.price.p).toFixed(2)
    }

    const clickDetail2 = () => {
        if(props.openDetail2 != null) props.openDetail2(props.row.company.stock_no)
    }

    return (
        <>
            {openSetting ? <AllIntChart row={props.row} sid={props.row.company.stock_no} open={openSetting} close={setOpenSetting}></AllIntChart> : null}

            <Grid container onClick={e =>clickDetail()}>
                <Grid item xs>
                    <Stack sx={{ justifyContent: 'center', width: "100%", mt: 0.5 }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >年化報酬率</Typography>
                        <Typography sx={{ fontWeight: "normal", alignSelf: 'center', fontSize: 48, color: addIncColor(props.row.it) }}>{props.row.it}%</Typography>
                    </Stack>
                </Grid>
            </Grid>
            <Divider />
            <Grid container onClick={e =>clickDetail()}>
                <Grid item xs>
                    <Stack sx={{ justifyContent: 'center', width: "100%", mt: 0.5 }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >資本利得報酬</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 22, color: addIncColor(props.row.is) }}>{props.row.is}%</Typography>
                    </Stack>
                </Grid>
                <Divider orientation="vertical" flexItem>

                </Divider>
                <Grid item xs>
                    <Stack sx={{ justifyContent: 'center', width: "100%", mt: 0.5 }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >股利報酬</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 22, color: addIncColor(props.row.ic) }}>{props.row.ic}%</Typography>
                    </Stack>
                </Grid>

            </Grid>

            <Divider />

            <Stack sx={{ justifyContent: 'center', width: "100%" }} onClick={e => clickDetail2()}>
                <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >日線圖</Typography>
                <KChart prices={props.row.prices} />
            </Stack>

            {props.row.mii != null ? <Typography sx={{ mt: 2, ml: 1 }} variant="caption">{props.row.mii.ymd}{" "}{props.row.mii.name.substr(0, 10)}</Typography>: <Typography sx={{ mt: 2, ml: 1 }} variant="caption">{"最近一次發放股利"}</Typography>}
            <Divider />
            {props.row.mii != null ? <Grid container onClick={e => setOpenSetting(true)}>
                <Grid item xs>
                    <Stack sx={{ justifyContent: 'center', width: "100%", mt: 0.5 }}> 
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >殖利率</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 22 }}>{getYield()}%</Typography>
                    </Stack>
                </Grid>
                <Divider orientation="vertical" flexItem>

                </Divider>
                <Grid item xs>
                    <Stack sx={{ justifyContent: 'center', width: "100%", mt: 0.5 }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >現金</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 22 }}>{props.row.mii.cash}元</Typography>
                    </Stack>
                </Grid>
                <Divider orientation="vertical" flexItem>

                </Divider>
                <Grid item xs>
                    <Stack sx={{ justifyContent: 'center', width: "100%", mt: 0.5 }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >盈餘分配率</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 22 }}>{props.row.mii.rate}%</Typography>
                    </Stack>
                </Grid>
            </Grid>: <Grid container >
                <Grid item xs>
                    <Stack sx={{ justifyContent: 'center', width: "100%", mt: 0.5 }}> 
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >殖利率</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 22 }}>x</Typography>
                    </Stack>
                </Grid>
                <Divider orientation="vertical" flexItem>

                </Divider>
                <Grid item xs>
                    <Stack sx={{ justifyContent: 'center', width: "100%", mt: 0.5 }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >現金</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 22 }}>x</Typography>
                    </Stack>
                </Grid>
                <Divider orientation="vertical" flexItem>

                </Divider>
                <Grid item xs>
                    <Stack sx={{ justifyContent: 'center', width: "100%", mt: 0.5 }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >盈餘分配率</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 22 }}>x</Typography>
                    </Stack>
                </Grid>
            </Grid>}
            <Divider />

        </>
    );
}