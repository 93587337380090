import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Container, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import { deepOrange, green, grey, red } from '@mui/material/colors';
import Badge from '@mui/material/Badge';
import KChart from '../rec/KChart';
import { priceWord, addIncColor } from '../util/Money'
import { styled } from '@mui/material/styles';
import MuiGrid from '@mui/material/Grid';
import LinearWithValueLabel from './LinearWithValueLabel';
import TransDialog from './TransDialog';
import AllIntChart from '../rec/AllIntChart'

const Grid = styled(MuiGrid)(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& [role="separator"]': {
        margin: theme.spacing(0, 2),
    },
}));

export default function MetaItem(props) {
    const theme = useTheme();
    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [openSetting2, setOpenSetting2] = React.useState(false);

    const getColor = (value) => {
        if (value > 0) return "error"
        else if (value < 0) return "success"
        else return "inherit"
    }

    // const clickDetail = () => {
    //     if (props.openDetail1 != null) props.openDetail1(props.row.company.stock_no)
    // }

    const clickDetail = () => {
        setOpenSetting1(true)
    }

    return (
        <>
            {openSetting1 && <TransDialog refresh={props.refresh} tid={props.tid} row={props.row} open={openSetting1} close={setOpenSetting1} />}
            {openSetting2 ? <AllIntChart row={props.row} sid={props.row.company.stock_no} open={openSetting2} close={setOpenSetting2}></AllIntChart> : null}



            <Grid container onClick={e =>setOpenSetting2(true)}>
                <Grid item xs>
                    <Stack sx={{ justifyContent: 'center', width: "100%", mt: 0.5 }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >殖利率</Typography>
                        <Typography sx={{ fontWeight: "normal", alignSelf: 'center', fontSize: 48 }}>{props.row.y4}%</Typography>
                    </Stack>
                </Grid>
            </Grid>


            <Divider />
            <Stack sx={{ justifyContent: 'center', width: "100%" }}>
                <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >日線圖</Typography>
                <KChart prices={props.row.prices} />
            </Stack>
            <Divider sx={{ mt: 1 }} />

            <Grid container onClick={e => clickDetail()}>

                <Grid item xs>
                    <Stack sx={{ justifyContent: 'center', width: "100%", mt: 0.5 }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >庫存目標</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 22 }}>{props.row.piece / 1000}張</Typography>
                    </Stack>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs>
                    <Stack sx={{ justifyContent: 'center', width: "100%", mt: 0.5 }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >庫存</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 22 }}>{props.row.rl_piece / 1000}張</Typography>
                    </Stack>


                </Grid>
            </Grid>
            <LinearWithValueLabel value={((props.row.rl_piece * 100) / props.row.piece)} ></LinearWithValueLabel>
            <Divider sx={{ mt: 0 }}></Divider>
            {/* <Divider sx={{ borderBottomWidth: 16, mt:0 }}></Divider> */}
            <Grid container onClick={e => clickDetail()}>


                <Grid item xs>
                    <Stack sx={{ justifyContent: 'center', width: "100%", mt: 0.5 }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >年股利目標</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 22 }}>{priceWord(props.row.money)}</Typography>
                    </Stack>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs>
                    <Stack sx={{ justifyContent: 'center', width: "100%", mt: 0.5 }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >預估股利</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 22 }}>{priceWord(props.row.y4_money)}</Typography>
                    </Stack>

                </Grid>
            </Grid>
            <LinearWithValueLabel color="warning" value={((props.row.y4_money * 100) / props.row.money)}></LinearWithValueLabel>
            <Divider />


            {/* <Grid container onClick={e => clickDetail()}>
                <Grid item xs>
                    <Stack sx={{ justifyContent: 'center', width: "100%", mt: 0.5 }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >股利目標</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 22, color: addIncColor(props.row.is) }}>{6000}元 / 50萬元</Typography>
                    </Stack>
                </Grid>
                
            </Grid>
            <Divider /> */}
            <Grid container onClick={e => clickDetail()}>
                <Grid item xs>
                    <Stack sx={{ justifyContent: 'center', width: "100%", mt: 0.5 }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >成本</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 22 }}>{(props.row.avg_price).toFixed(2)}元</Typography>
                    </Stack>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs>
                    <Stack sx={{ justifyContent: 'center', width: "100%", mt: 0.5 }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >總盈虧</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 22, color: addIncColor(props.row.roi) }}>{props.row.roi}%</Typography>
                    </Stack>
                </Grid>
            </Grid>
            <Divider />

            {/* <Box onClick={e => openDetail1()} sx={{ p: 1.2, textAlign: "center", bgcolor: red[900], color: "#FFFFFF" }} >新增記錄</Box> */}

        </>
    );
}