import React, { useEffect, useRef } from 'react';
import RecItem from './RecItem'
import { Height } from '../util/ScreenHeight'
import RecSelector from './RecSelector'
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { getId } from '../util/ID'

import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material';
import DetailChart1 from './DetailChart1'
import DetailKChart from '../rec/DetailKChart'
import { putInpQueryParam } from '../util/ID'
import MonthIntChart from './MonthIntChart';
import AssetChart from './AssetChart';
import RecHeader from './RecHeader';



export default function Rec1Container(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(1)
    const [count, setCount] = React.useState(0)

    const [result, setResult] = React.useState(null);
    const [menu, setMenu] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [tp, setTp] = React.useState(0)
    const [rules, setRules] = React.useState(null)
    const [openSetting, setOpenSetting] = React.useState(false);
    const [stock, setStock] = React.useState(null);

    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [openSetting2, setOpenSetting2] = React.useState(false);
    const [id, setId] = React.useState(null);
    const [sid, setSid] = React.useState(null);

    const [regTp, setRegTp] = React.useState(-1);
    const [runYear, setRunYear] = React.useState(-1);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const updateSelector = (selectorTp, selectorYear) => {
        setRegTp(selectorTp)
        setRunYear(selectorYear)
    }

    const fetchData = () => {
        setLoading(true)

        let param = {
            sn: getId(),
            tid: regTp
        }

        if (localStorage.getItem("reg_lic") != null) {
            param['lic'] = localStorage.getItem("reg_lic")
        }


        fetch("/regular/v1/target/getOne", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setLoading(false)

                    if (result != null && result.status === 0) {
                        if (result.target1 != null) {
                            setResult(result.target1)
                        }
                    }
                }
            );
    }

    useEffect(() => {
        if (regTp != -1 && runYear != -1) fetchData()
    }, [page, regTp, runYear]);



    // useEffect(() => {
    //     if (props.sig != null) {
    //         setPage(1)
    //         setMenu(props.sig)
    //     }
    // }, [props.sig]);


    const updateLove = (sid) => {
        if (sid != null && result != null) {
            let nresult = result.map(r => {
                if (r.stock === sid) {
                    r['islv'] = !r.islv
                    return r
                } else return r
            })
            setResult(nresult)
        }
    }

    const openDetail = (stock) => {
        setStock(stock)
        setOpenSetting(true)
    }

    const openDetail1 = (sid) => {
        setSid(sid)
        setOpenSetting1(true)
    }

    const openDetail2 = (id) => {
        setSid(id)
        setOpenSetting2(true)
    }

    return (
        <Box style={{ flexDirection: 'column', height: '100%', overflowY: 'hidden' }} sx={{}}>
            {openSetting1 && <DetailChart1 regTp={regTp} runYear={runYear} id={id} sid={sid} open={openSetting1} close={setOpenSetting1} />}
            {openSetting2 && <DetailKChart sid={sid} open={openSetting2} close={setOpenSetting2} />}


            <Box style={{ flexDirection: 'column', height: '100%', overflowY: 'hidden' }} sx={{}}>
                <RecSelector money={result != null ? result.money : 50000} fetchData={fetchData} setMenu={setMenu} refresh={updateSelector} enableSetting={true} />

                {loading ? <Box sx={{ position: 'absolute', top: Height.header + Height.subheader - 2, width: '100%' }}><LinearProgress color="info" /></Box> : <Grid container spacing={0} sx={{ p: 0.5, width: '100%', bgcolor: 'background.paper', position: 'absolute', top: Height.header + Height.subheader, bottom: props.ismobile || fullScreen ? 60 : 5, overflowY: 'scroll' }}>
                    {/* <Grid key={"mi"} item xs={12} sm={6} md={6} lg={6}>
                        <AssetChart />
                    </Grid>
                    <Grid key={"mi1"} item xs={12} sm={6} md={6} lg={6}>
                        <MonthIntChart />
                    </Grid> */}
                    {result != null && <Grid key={"header"} item xs={12} sm={12} md={12} lg={12}>
                        <RecHeader money={result.money} mm={result.mm} refresh={fetchData} tid={result.tid} openDetail2={openDetail2} openDetail1={openDetail1} openDetail={openDetail} updateLove={updateLove} tp={tp} menu={menu} />
                    </Grid>}

                    {result != null && result.lt != null && result.lt.map(row => {
                        return <Grid key={row.stock} item xs={12} sm={6} md={4} lg={3}>
                            <RecItem refresh={fetchData} tid={result.tid} openDetail2={openDetail2} openDetail1={openDetail1} openDetail={openDetail} updateLove={updateLove} tp={tp} key={row.stock} row={row} menu={menu} />
                        </Grid>
                    })}
                    {result != null && result.lt != null && result.lt.length === 0 &&
                        <Typography variant="body1" sx={{ width: '100%', textAlign: 'center', alignSelf: 'center', m: 1 }}>很抱歉，無法找到符合條件的股票。</Typography>
                    }

                </Grid>}
            </Box >


        </Box >
    );
}
