import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { getId } from '../util/ID'


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MuiGrid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { blue, green, grey, orange, red } from '@mui/material/colors';

const Grid = styled(MuiGrid)(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& [role="separator"]': {
        margin: theme.spacing(0, 2),
    },
}));

function createData(
    name,
    calories,
    fat,
    carbs,
    protein,
) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];


echarts.use(
    [VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent, BarChart]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        // width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));



export default function DetailChart(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);

    const [open, setOpen] = React.useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);

    function genoption1() {
        let k = {
            animation: false,
            tooltip: {
                show: true
            },
            title: [
                {
                    top: '20',
                    left: 'center',
                    text: result.item.name,
                    textStyle: {
                        fontSize: 22
                    }
                },
            ],
            grid: [{
                top: 55,
                left: 0,
                right: 40,
                bottom: 0,
                containLabel: true
            }],
            axisPointer: {
                show: true
            },
            xAxis: [{
                type: 'category',
                boundaryGap: false,
                data: result.data.map(row => row.x),
            }],
            yAxis: [{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
            }],
            series: [
                {
                    name: result.item.name,
                    type: 'line',
                    data: result.data.map(row => row.y),
                    smooth: true,
                    showSymbol: true,
                    symbolSize: 8,
                    endLabel: {
                        show: true
                    },
                    lineStyle: {
                        width: 4
                    }
                }
            ]
        };
        return k;
    }

    const fetchData = () => {
        let sort = localStorage.getItem('reg_search_sort') != null ? localStorage.getItem('reg_search_sort') : '0'
        let money = localStorage.getItem('reg_search_money') != null ? localStorage.getItem('reg_search_money') : '10000'
        let asset_inc = localStorage.getItem('reg_search_asset_inc') != null ? localStorage.getItem('reg_search_asset_inc') : '10000'
        let buy_num = localStorage.getItem('reg_search_buy_num') != null ? localStorage.getItem('reg_search_buy_num') : '1000'

        let param = {
            sn: getId(),
            unit: 0,
            money: money,
            ttp: props.regTp,
            run_year: props.runYear,
            sort: sort,
            asset_inc: asset_inc,
            buy_num: buy_num,
            stock: props.sid
        }

        if (localStorage.getItem("reg_lic") != null) {
            param['lic'] = localStorage.getItem("reg_lic")
        }

        fetch("/regular/v1/app/detail", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.status === 0) {
                        setResult(result.result2)
                    }
                }
            );
    }


    useEffect(() => {
        if (props.sid != null) fetchData()
    }, [props.sid]);

    // useEffect(() => {
    //     if (result != null) setOption(genoption1)
    // }, [result]);

    const getColor = () => {
        if (result != null && result.price != null) {
            if (result.price.i > 0) return "red"
            else if (result.price.i < 0) return "green"
            else return null
        } else return null
    }

    const getInv = () => {
        if (result != null && result.price != null) {
            if (result.price.i > 0) return "▲" + result.price.i + '元'
            else if (result.price.i < 0) return "▼" + result.price.i + '元'
            else return result.price.i + '元'
        } else if (result.price != null)
            return result.price.i + '元'
        else return '▲0元'
    }

    const getAction = (tp) => {
        if (tp === 0) {
            return <Box sx={{ whiteSpace: "nowrap", color: 'white', bgcolor: red[300], p: 1 }} >買進</Box>
        } else if (tp === 1) {
            return <Box sx={{ whiteSpace: "nowrap", color: 'white', bgcolor: green[300], p: 1 }} >賣出</Box>
        } else if (tp === 2) {
            return <Box sx={{ whiteSpace: "nowrap", color: 'white', bgcolor: orange[300], p: 1 }} >現金股利</Box>
        } else if (tp === 3) {
            return <Box sx={{ whiteSpace: "nowrap", color: 'white', bgcolor: blue[700], p: 1 }} >股票股利</Box>
        } else return null
    }

    const drawBox = (value, unit) => {
        if (value > 0) {
            return <Box sx={{ whiteSpace: "nowrap", color: 'white', bgcolor: red[300], p: 1 }} >{value}{unit}</Box>
        } else if (value < 0) {
            return <Box sx={{ whiteSpace: "nowrap", color: 'white', bgcolor: green[300], p: 1 }} >{value}{unit}</Box>
        } else return <Box sx={{ p: 1 }} >{value}{unit}</Box>
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
        >
            <DialogTitle sx={{ p: 0 }}>
            </DialogTitle>
            <DialogContent sx={{ pl: 1, pr: 1 }}>
                {result != null && <Box display={'flex'}>
                    <Stack direction='row' spacing={0.5} sx={{ flex: 1, p: 0, pl: 0, pt: 0.5, alignSelf: 'center' }} >
                        <Typography sx={{ fontSize: 30, fontWeight: 'bold', alignSelf: 'center' }} >{result.company.short_name}</Typography>
                        <Typography variant="caption" sx={{ fontWeight: 'normal' }} >{result.stock}</Typography>
                    </Stack>
                    <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }} >
                        <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{result.price != null ? result.price.p : "-"}</Typography>
                        <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{getInv()}</Typography>
                    </Stack>
                </Box>}

                <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                    style={{
                        border: "none",
                        height: 0.5,
                        margin: 0,
                    }} />

                {result != null && <Grid container sx={{ mt: 0 }} spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Typography variant='h6' fontWeight={"bold"}>總覽</Typography>

                        <TableContainer component={Paper} >
                            <Table sx={{ fontSize: 25 }} size='small'>
                                <TableHead sx={{ bgcolor: grey[300] }}>
                                    <TableRow>
                                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">名稱</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">數值</TableCell>
                                    </TableRow>
                                </TableHead>
                                {result != null && <TableBody>
                                    <TableRow key={"年化報酬率"} >
                                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">{"年化報酬率"}</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">{drawBox(result.irr_total, "%")}</TableCell>
                                    </TableRow>
                                    <TableRow key={"資本利得報酬"} >
                                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">{"資本利得報酬"}</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">{drawBox(result.irr_stock, "%")}</TableCell>
                                    </TableRow>
                                    <TableRow key={"股利報酬"} >
                                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">{"股利報酬"}</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">{drawBox(result.irr_cash, "%")}</TableCell>
                                    </TableRow>
                                    <TableRow key={"庫存數量"} >
                                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">{"庫存數量"}</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">{result.stock_num}股</TableCell>
                                    </TableRow>

                                    <TableRow key={"買入成本"} >
                                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">{"買入成本"}</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">{result.total_cost}元</TableCell>
                                    </TableRow>
                                    <TableRow key={"總價值"} >
                                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">{"總價值"}</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">{result.total_earn_plus_cost}元</TableCell>
                                    </TableRow>
                                    <TableRow key={"資本利得"} >
                                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">{"資本利得"}</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">{result.stock_earn}元</TableCell>
                                    </TableRow>
                                    <TableRow key={"股利所得"} >
                                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">{"股利所得"}</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">{result.cash_earn}元</TableCell>
                                    </TableRow>

                                    <TableRow key={"總盈虧"} >
                                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">{"盈虧"}</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">{result.total_earn}元</TableCell>
                                    </TableRow>
                                </TableBody>}
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        <Typography variant='h6' fontWeight={"bold"}>操作明細</Typography>
                        <TableContainer component={Paper} >
                            <Table sx={{ fontSize: 25 }} size='small'>
                                <TableHead sx={{ bgcolor: grey[300] }}>
                                    <TableRow>
                                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">日期</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">操作</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">股數</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">價格</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">金額</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {result != null && result.detail != null
                                        && result.detail.map((row) => (
                                            <TableRow
                                                key={row.ymd}
                                                sx={{}}
                                            >
                                                <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">
                                                    {row.ymd}
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">{getAction(row.tp)}</TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">{row.stock}股</TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">{row.price}元</TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap", fontSize: 18 }} align="center">{row.money}元</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>}


                {/* {option != null ?
                    <ReactEChartsCore
                        theme={theme.palette.mode}
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: '80vh' }}
                    />
                    : null} */}
            </DialogContent>
            <DialogActions>
                {/* <Box sx={{fontSize:18, width:"100%", color: 'white', bgcolor: grey[900], p: 1, textAlign:"center" }}  onClick={handleClose}>關閉</Box> */}
                <Button disableElevation color="inherit" fullWidth variant='contained' onClick={handleClose}>關閉</Button>
            </DialogActions>
        </Dialog>
    );
}

