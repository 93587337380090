import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

echarts.use(
    [VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent, BarChart]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        // width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));



export default function AllIntChart(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);
    const [result1, setResult1] = React.useState(null);

    const [open, setOpen] = React.useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);

    function genoption1() {
        const cresult = [...result].reverse()

        let k = {
            animation: false,
            tooltip: {
                show: true
            },
            legend: {
                data: ['現金殖利率'],
                bottom: 0
            },
            // title: [
            //     {
            //         top: '20',
            //         left: 'center',
            //         text: result.item.name,
            //         textStyle: {
            //             fontSize: 22
            //         }
            //     },
            // ],
            grid: [{
                top: 40,
                left: 0,
                right: 0,
                bottom: 40,
                containLabel: true
            }],
            axisPointer: {
                show: true
            },
            xAxis: [{
                type: 'category',
                boundaryGap: false,
                data: cresult.map(row => row.s.cash_go_ymd),
                boundaryGap: true,
            }],
            yAxis: [{
                scale: false,
                type: 'value',
                position: 'left',
                show: true,
            }],
            series: [
                {
                    name: "現金殖利率",
                    type: 'line',
                    data: cresult.map(row => row.r),
                    smooth: true,
                    showSymbol: true,
                    symbolSize: 8,
                    label:{
                        // show: true
                    },
                    lineStyle: {
                        width: 4
                    }
                }
            ]
        };
        return k;
    }

    const fetchData = () => {
        let param = {
            sid: props.sid,
        }

        if (localStorage.getItem("reg_lic") != null) {
            param['lic'] = localStorage.getItem("reg_lic")
        }

        fetch("/regular/v1/int/all", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result)
                    }
                }
            );
    }


    useEffect(() => {
        if (props.sid != null) fetchData()
    }, []);

    useEffect(() => {
        if (result != null)
            setOption(genoption1)
    }, [result]);

    const getColor = () => {
        if (result != null && result.price != null) {
            if (result.price.i > 0) return "red"
            else if (result.price.i < 0) return "green"
            else return null
        } else return null
    }

    const getInv = () => {
        if (props.row != null && props.row.price != null) {
            if (props.row.price.i > 0) return "▲" + props.row.price.i + '元'
            else if (props.row.price.i < 0) return "▼" + props.row.price.i + '元'
            else return props.row.price.i + '元'
        } else if (props.row.price != null)
            return props.row.price.i + '元'
        else return '▲0元'
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
        >
            <DialogTitle sx={{ p: 0 }}>
            </DialogTitle>
            <DialogContent sx={{ pl: 1, pr: 1 }}>
                {result != null && <Box display={'flex'}>
                    <Stack direction='row' spacing={0.5} sx={{ flex: 1, p: 0, pl: 0, pt: 0.5, alignSelf: 'center' }} >
                        <Typography sx={{ fontSize: 30, fontWeight: 'bold', alignSelf: 'center' }} >{props.row.company.short_name}</Typography>
                        <Typography variant="caption" sx={{ fontWeight: 'normal' }} >{props.row.company.stock_no}</Typography>
                    </Stack>
                    <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }} >
                        <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{props.row.price != null ? props.row.price.p : "-"}</Typography>
                        <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{getInv()}</Typography>
                    </Stack>
                </Box>}

                <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                    style={{
                        border: "none",
                        height: 0.5,
                        margin: 0,
                    }} />

                {option != null ?
                    <ReactEChartsCore
                        theme={theme.palette.mode}
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 280 }}
                    />
                    : null}

                {result != null && result.length === 0 && <Typography variant='body2'>目前沒有任何紀錄</Typography>}

                {result != null && result.length > 0 && <TableContainer component={Paper} sx={{marginTop: 3}} >
                    <Table size="medium" >
                        <TableHead sx={{ bgcolor: 'black' }}>
                            <TableRow>
                                <TableCell sx={{ color: "white" }}>日期</TableCell>
                                <TableCell sx={{ color: "white" }} align="center">現金</TableCell>
                                <TableCell sx={{ color: "white" }} align="center">股票</TableCell>
                                <TableCell sx={{ color: "white" }} align="center">現金殖利率</TableCell>
                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {result.map((row) => (
                                <TableRow
                                    key={row.s.cash_go_ymd}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.s.cash_go_ymd}
                                    </TableCell>
                                    <TableCell align="center">{(row.s.cash).toFixed(2)}元</TableCell>
                                    <TableCell align="center">{(row.s.stock).toFixed(2)}元</TableCell>
                                    <TableCell align="center">
                                        <Button color="inherit" fullWidth disableElevation variant="contained">{row.r}%</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}

            </DialogContent>
            <DialogActions>
                <Button fullWidth disableElevation variant="contained" color="inherit" onClick={handleClose}>關閉</Button>
            </DialogActions>
        </Dialog>
    );
}

