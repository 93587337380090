import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import AllStrageRules from '../strage/AllStrageRules'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchCompany from './SearchCompany'
import { getId } from '../util/ID'
import AlertD from '../util/AlertD';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as dayjs from 'dayjs'
dayjs.locale("zh-tw");

export default function TranDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [price, setPrice] = React.useState(null);
    const [piece, setPiece] = React.useState(null);
    const [tp, setTP] = React.useState(0);
    const [ymd, setYMD] = React.useState(null);

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const fetchData = () => {
        let param = {
            sn: getId(),
            tid: props.tid,
            lid: props.row.id,
            stock: props.row.stock,
            piece: piece,
            price: price,
            tp: tp
        }

        if (ymd != null) {
            param['ymd'] = dayjs(ymd).format('YYYYMMDD')
        }


        if (localStorage.getItem("reg_lic") != null) {
            param['lic'] = localStorage.getItem("reg_lic")
        }


        fetch("/regular/v1/target/tran", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setOpen(false);
                    if (props.close != null) props.close(false)

                    if (props.refresh != null) {
                        props.refresh()
                    }
                }
            );
    }

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    const handleOKClose = () => {
        if (piece == null) {
            setAlertMsg("請輸入股數")
            setShowAlert(true)
        } else if (price == null) {
            setAlertMsg("請輸入價格")
            setShowAlert(true)
        } else {
            fetchData()
        }
    };

    useEffect(() => {
        if (props.open) {
            setOpen(true)
            // setYMD(dayjs(new Date().toISOString()).format('YYYY/MM/DD'))
        }
        else setOpen(false)
    }, [props.open]);

    const handleChange = (event) => {
        setTP(event.target.value);
      };
    

    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"盤點庫存"}
                </DialogTitle>
                <DialogContent sx={{ pl: 1, pr: 1 }}>

                    <Stack spacing={3} direction="column" sx={{ p: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker format="YYYY/MM/DD" label="日期" value={ymd} onChange={(newValue) => setYMD(newValue)} />
                        </LocalizationProvider>
                        {/* <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">交易</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={tp}
                                label="交易"
                                onChange={handleChange}
                            >
                                <MenuItem value={0}>買</MenuItem>
                                <MenuItem value={1}>賣</MenuItem>
                            </Select>
                        </FormControl> */}

                        <TextField
                            fullWidth
                            id="outlined-controlled"
                            label="庫存數量"
                            value={piece}
                            onChange={(event) => {
                                setPiece(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">股</InputAdornment>,
                            }}
                        />

                        <TextField
                            fullWidth
                            id="outlined-controlled"
                            label="平均成本"
                            value={price}
                            onChange={(event) => {
                                setPrice(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">元</InputAdornment>,
                            }}
                        />
                    </Stack>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    {/* isabled={localStorage.getItem("noad") != "1"} */}
                    <Button d onClick={handleOKClose} autoFocus>
                    更新
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
