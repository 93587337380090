import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

echarts.use(
    [VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent, BarChart]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        // width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));



export default function DetailKChart(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [option1, setOption1] = React.useState(null);
    const [option2, setOption2] = React.useState(null);

    const [result, setResult] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);


    function genoption2(vv, title) {
        let k = {
            animation: false,
            tooltip: {
                show: true
            },
            legend: {
                show: true
            },
            grid: [{
                top: 60,
                left: 0,
                right: 10,
                bottom: 0,
                containLabel: true
            }],
            axisPointer: {
                show: true
            },
            xAxis: [{
                type: 'category',
                boundaryGap: false,
                data: vv.map(row => row.x),
            }],
            yAxis: [{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                axisLabel: {
                    inside: true,
                    margin: 0,
                    fontWeight: "bold",
                    fontSize: 12
                },
            }],
            dataZoom: [
                {
                    type: 'inside',
                    start: fullScreen ? 75 : 0,
                    end: 100
                }
            ],
            series: [
                {
                    name: title,
                    type: 'candlestick',
                    data: vv.map(v => { return v.y }),
                    itemStyle: {
                        color: "#d41c1c",
                        color0: '#65b206',
                        borderColor: "#d41c1c",
                        borderColor0: '#65b206'
                    },
                    selectedMode: 'single',
                }, {
                    name: 'MA5',
                    type: 'line',
                    data: vv.map(v => { return v.a1 }),
                    showSymbol: false,
                }, {
                    name: 'MA10',
                    type: 'line',
                    data: vv.map(v => { return v.a2 }),
                    showSymbol: false,
                }, {
                    name: 'MA20',
                    type: 'line',
                    data: vv.map(v => { return v.a3 }),
                    showSymbol: false,
                }, {
                    name: 'MA40',
                    type: 'line',
                    data: vv.map(v => { return v.a4 }),
                    showSymbol: false,
                }, {
                    name: 'MA60',
                    type: 'line',
                    data: vv.map(v => { return v.a5 }),
                    showSymbol: false,
                }, {
                    name: 'MA120',
                    type: 'line',
                    data: vv.map(v => { return v.a6 }),
                    showSymbol: false,
                }, {
                    name: 'MA240',
                    type: 'line',
                    data: vv.map(v => { return v.a7 }),
                    showSymbol: false,
                },
            ]
        };

        let av1i = localStorage.getItem('av1i') === null ? true : localStorage.getItem('av1i').toLowerCase() === 'true'
        let av2i = localStorage.getItem('av2i') === null ? true : localStorage.getItem('av2i').toLowerCase() === 'true'
        let av3i = localStorage.getItem('av3i') === null ? true : localStorage.getItem('av3i').toLowerCase() === 'true'
        let av4i = localStorage.getItem('av4i') === null ? false : localStorage.getItem('av4i').toLowerCase() === 'true'
        let av5i = localStorage.getItem('av5i') === null ? false : localStorage.getItem('av5i').toLowerCase() === 'true'
        let av6i = localStorage.getItem('av6i') === null ? false : localStorage.getItem('av6i').toLowerCase() === 'true'
        let av7i = localStorage.getItem('av7i') === null ? false : localStorage.getItem('av7i').toLowerCase() === 'true'

        let selectedo = {}
        selectedo["MA5"] = av1i
        selectedo["MA10"] = av2i
        selectedo["MA20"] = av3i
        selectedo["MA40"] = av4i
        selectedo["MA60"] = av5i
        selectedo["MA120"] = av6i
        selectedo["MA240"] = av7i
        k['legend']['selected'] = selectedo

        return k;
    }

    const onChartLegendselectchanged = (event) => {
        let av1v = event.selected['MA5']
        let av2v = event.selected['MA10']
        let av3v = event.selected['MA20']
        let av4v = event.selected['MA40']
        let av5v = event.selected['MA60']
        let av6v = event.selected['MA120']
        let av7v = event.selected['MA240']

        if (av1v != null && av1v) {
            localStorage.setItem('av1i', true)
        } else localStorage.setItem('av1i', false)

        if (av2v != null && av2v) {
            localStorage.setItem('av2i', true)
        } else localStorage.setItem('av2i', false)

        if (av3v != null && av3v) {
            localStorage.setItem('av3i', true)
        } else localStorage.setItem('av3i', false)

        if (av4v != null && av4v) {
            localStorage.setItem('av4i', true)
        } else localStorage.setItem('av4i', false)

        if (av5v != null && av5v) {
            localStorage.setItem('av5i', true)
        } else localStorage.setItem('av5i', false)

        if (av6v != null && av6v) {
            localStorage.setItem('av6i', true)
        } else localStorage.setItem('av6i', false)

        if (av7v != null && av7v) {
            localStorage.setItem('av7i', true)
        } else localStorage.setItem('av7i', false)

    }

    const onEvents = {
        'legendselectchanged': onChartLegendselectchanged
    }

    const fetchData = () => {
        let param = {
            sid: props.sid,
        }

        if (localStorage.getItem("reg_lic") != null) {
            param['lic'] = localStorage.getItem("reg_lic")
        }

        fetch("/regular/v1/info/get", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result)
                    }
                }
            );
    }


    useEffect(() => {
        if (props.sid != null) fetchData()
    }, []);

    useEffect(() => {
        if (result != null) {
            setOption(genoption2(result.v1, "日K"))
            setOption1(genoption2(result.v2, "周K"))
            setOption2(genoption2(result.v3, "月K"))
        }
    }, [result]);

    const getColor = () => {
        if (result != null && result.price != null) {
            if (result.price.i > 0) return "red"
            else if (result.price.i < 0) return "green"
            else return null
        } else return null
    }

    const getInv = () => {
        if (result != null && result.price != null) {
            if (result.price.i > 0) return "▲" + result.price.i + '元'
            else if (result.price.i < 0) return "▼" + result.price.i + '元'
            else return result.price.i + '元'
        } else if (result.price != null)
            return result.price.i + '元'
        else return '▲0元'
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
        >
            <DialogTitle sx={{ p: 0 }}>
            </DialogTitle>
            <DialogContent sx={{ pl: 1, pr: 1 }}>
                {result != null && <Box display={'flex'}>
                    <Stack direction='row' spacing={0.5} sx={{ flex: 1, p: 0, pl: 0, pt: 0.5, alignSelf: 'center' }} >
                        <Typography sx={{ fontSize: 30, fontWeight: 'bold', alignSelf: 'center' }} >{result.company.short_name}</Typography>
                        <Typography variant="caption" sx={{ fontWeight: 'normal' }} >{result.company.stock_no}</Typography>
                    </Stack>
                    <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }} >
                        <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{result.price != null ? result.price.p : "-"}</Typography>
                        <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{getInv()}</Typography>
                    </Stack>
                </Box>}

                <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                    style={{
                        border: "none",
                        height: 0.5,
                        margin: 0,
                    }} />

                {option != null ?
                    <ReactEChartsCore
                        theme={theme.palette.mode}
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: '60vh', marginTop: 20 }}
                        onEvents={onEvents}
                    /> : null}


                {option1 != null ?
                    <ReactEChartsCore
                        theme={theme.palette.mode}
                        echarts={echarts}
                        option={option1}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: '60vh', marginTop: 50 }}
                        onEvents={onEvents}
                    /> : null}


                {option2 != null ?
                    <ReactEChartsCore
                        theme={theme.palette.mode}
                        echarts={echarts}
                        option={option2}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: '60vh', marginTop: 50 }}
                        onEvents={onEvents}
                    /> : null}
            </DialogContent>
            <DialogActions>
                <Button fullWidth disableElevation variant="contained" color="inherit" onClick={handleClose}>關閉</Button>
            </DialogActions>
        </Dialog>
    );
}

