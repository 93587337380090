import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Lightbulb, PropaneTwoTone } from '@mui/icons-material';
import FavoriteBorderOutlined from '@mui/icons-material/FavoriteBorderOutlined'
import Favorite from '@mui/icons-material/Favorite'
import { getId } from '../util/ID'

import Avatar from '@mui/material/Avatar';
import { deepOrange, green } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ButtonGroup from '@mui/material/ButtonGroup';
import MetaItem from './MetaItem';
import ActionMenu from './ActionMenu';
import TranDialog from './TranDialog'
import AlertD from '../util/AlertD';
import UpdateTarget from './UpdateTarget';
import AllIntChart from '../rec/AllIntChart'

export default function RecItem(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [showOKAlert, setShowOKAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);
    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [openSetting2, setOpenSetting2] = React.useState(false);
    const [openSetting3, setOpenSetting3] = React.useState(false);

    const openMenu = Boolean(anchorEl);


    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const openDetail = (dtp) => {
    }

    const getColor = () => {
        if (props.row != null && props.row.price != null) {
            if (props.row.price.i > 0) return "red"
            else if (props.row.price.i < 0) return "green"
            else return null
        } else return null
    }

    const getInv = () => {
        if (props.row != null && props.row.price != null) {
            if (props.row.price.i > 0) return "▲" + props.row.price.i + '元'
            else if (props.row.price.i < 0) return "▼" + props.row.price.i + '元'
            else return props.row.price.i + '元'
        } else if (props.row.price != null)
            return props.row.price.i + '元'
        else return '▲0元'
    }

    const del = () => {
        setShowOKAlert(false)

        let param = {
            sn: getId(),
            tid: props.tid,
            lid: props.row.id
        }

        if (localStorage.getItem("reg_lic") != null) {
            param['lic'] = localStorage.getItem("reg_lic")
        }


        fetch("/regular/v1/target/r1/del", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (props.refresh != null) {
                        props.refresh()
                    }
                }
            );
    }

    const options = [
        '盤點庫存',
        '更新目標',
        '刪除',
    ];

    const menuOk = (option, id) => {
        if (option === 1) {
            setOpenSetting1(true)
        } else if (option === 6) {
            setAlertMsg("確定將此筆資料刪除?")
            setShowOKAlert(true)
        } else if (option === 7) {
            setOpenSetting2(true)
        }
    }


    return (
        <>
            {openSetting1 && <TranDialog refresh={props.refresh} tid={props.tid} row={props.row} open={openSetting1} close={setOpenSetting1} />}
            {openSetting2 && <UpdateTarget refresh={props.refresh} tid={props.tid} row={props.row} open={openSetting2} close={setOpenSetting2} />}

            {showOKAlert ? <AlertD desc={alertMsg} open={showOKAlert} close={setShowOKAlert} ok={del}></AlertD> : null}

            <Stack spacing={0} sx={{ m: 1, mb: 0.5, p: 0, border: 0.5, borderColor: theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main, boxShadow: 0, borderRadius: '0px' }}>

                <Box display={'flex'}>
                    <Stack direction='row' spacing={0.5} sx={{ flex: 1, p: 0, pl: 1, pt: 0.5, alignSelf: 'center' }} >
                        {/* {props.row.islv ? <Favorite color="error" onClick={e => clickLove()} /> :
                        <FavoriteBorderOutlined onClick={e => clickLove()} />} */}
                        <Typography sx={{ fontWeight: "bold", fontSize: 30, alignSelf: 'center', whiteSpace:"nowrap" }} onClick={e => setOpen(true)}>{props.row.company.short_name.substr(0, 5)}</Typography>
                        <Typography variant="caption" sx={{ fontWeight: 'normal' }} onClick={e => setOpen(true)}>{props.row.company.stock_no}</Typography>
                    </Stack>
                    <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }} onClick={e => setOpen(true)}>
                        <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{props.row.price != null ? props.row.price.p : "-"}</Typography>
                        <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{getInv()}</Typography>
                    </Stack>
                    <ActionMenu menuOk={menuOk} options={options} />
                </Box>
                {/* , bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main */}
                <Divider sx={{}}

                // style={{
                //     // border: "none",
                //     // height: 0.5,
                //     margin: 0,
                //     borderWidth: 6
                // }} 
                />
                <MetaItem refresh={props.refresh} tid={props.tid} row={props.row} openDetail2={props.openDetail2} openDetail1={props.openDetail1} openDetail={props.openDetail} menu={props.menu} setOpen={setOpen} />

                {/* <RecBase openDetail2={props.openDetail2} openDetail1={props.openDetail1} openDetail={props.openDetail} row={props.row} menu={props.menu} setOpen={setOpen} /> */}

                {/* <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    margin: 0,
                }} variant="middle" /> */}

            </Stack>
        </>
    );
}
