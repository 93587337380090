import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { getId } from '../util/ID'
import AlertD from '../util/AlertD';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as dayjs from 'dayjs'
import TargetChart from './TargetChart';



dayjs.locale("zh-tw");

export default function TargetDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [price, setPrice] = React.useState(null);
    const [piece, setPiece] = React.useState(null);
    const [tp, setTP] = React.useState(0);
    const [ymd, setYMD] = React.useState(null);

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };


    useEffect(() => {
        if (props.open) {
            setOpen(true)
            // setYMD(dayjs(new Date().toISOString()).format('YYYY/MM/DD'))
        }
        else setOpen(false)
    }, [props.open]);

    const handleChange = (event) => {
        setTP(event.target.value);
    };


    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"股利"}
                </DialogTitle>
                

                

                <DialogContent sx={{ pl: 1, pr: 1 }}>
                <TargetChart tid={props.tid} />
                    


                </DialogContent>
                <DialogActions>
                    <Button disableElevation color="inherit" fullWidth variant='contained' onClick={handleClose}>關閉</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
